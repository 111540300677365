<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.coupons.tabs.general.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.code.label')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="item.code"
                      :state="errors && errors.code ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.code">
                      {{ errors.code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="item.type !== 'shipping'"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.value.label')"
                    label-for="value"
                  >
                    <b-form-input
                      id="value"
                      v-model="item.value"
                      type="number"
                      :state="errors && errors.value ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.value">
                      {{ errors.value[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.started_at.label')"
                    label-for="started_at"
                    :state="errors && errors.started_at ? false : null"
                  >
                    <b-form-datepicker
                      id="started_at"
                      v-model="item.started_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.started_at">
                      {{ errors.started_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.ended_at.label')"
                    label-for="ended_at"
                    :state="errors && errors.ended_at ? false : null"
                  >
                    <b-form-datepicker
                      id="ended_at"
                      v-model="item.ended_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ended_at">
                      {{ errors.ended_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('admin.coupons.tabs.data.label')"
              @click="loadDataTab"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.minimum_spend.label')"
                    label-for="minimum_spend"
                  >
                    <b-form-input
                      id="minimum_spend"
                      v-model="item.minimum_spend"
                      type="number"
                      :state="errors && errors.minimum_spend ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.minimum_spend">
                      {{ errors.minimum_spend[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.maximum_spend.label')"
                    label-for="maximum_spend"
                  >
                    <b-form-input
                      id="maximum_spend"
                      v-model="item.maximum_spend"
                      type="number"
                      :state="errors && errors.maximum_spend ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.maximum_spend">
                      {{ errors.maximum_spend[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.usage_limit_per_coupon.label')"
                    label-for="usage_limit_per_coupon"
                  >
                    <b-form-input
                      id="usage_limit_per_coupon"
                      v-model="item.usage_limit_per_coupon"
                      type="number"
                      :state="errors && errors.usage_limit_per_coupon ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.usage_limit_per_coupon">
                      {{ errors.usage_limit_per_coupon[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                  >
                    <treeselect
                      id="categories"
                      v-model="item.categories"
                      :multiple="true"
                      :options="categoryOptions"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.products.label')"
                    label-for="products"
                    :state="errors && errors.products ? false : null"
                  >
                    <v-select
                      id="products"
                      v-model="item.products"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productOptions"
                      :reduce="val => val.value"
                      :taggable="true"
                      :searchable="true"
                      :multiple="true"
                      input-id="products"
                      @search="searchProduct"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.products">
                      {{ errors.products[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'coupons-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CategoryMixin from '@/mixins/CategoryMixin'

export default {
  mixins: [GlobalMixin, CategoryMixin],
  data() {
    return {
      item: null,
      categoryOptions: [],
      productOptions: [],
      excludeProductOptions: [],
      typeOptions: [
        { label: this.$t('general.coupon_types.percent'), value: 'percent' },
        { label: this.$t('general.coupon_types.fixed'), value: 'fixed' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get(`/api/admin/coupons/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        this.productOptions = this.$options.filters.transformForVSelect(data.products, 'id', 'title')
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'coupons-index' })
        }
      })
  },
  methods: {
    async searchProduct(query) {
      await this.$http.get('/api/admin/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        await this.$http.get('/api/admin/categories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categoryOptions = response.data
          })
      }
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        status: this.item.status,
        type: this.item.type,
        code: this.item.code,
        value: this.item.value,
        minimum_spend: this.item.minimum_spend,
        maximum_spend: this.item.maximum_spend,
        usage_limit_per_coupon: this.item.usage_limit_per_coupon,
        started_at: this.item.started_at,
        ended_at: this.item.ended_at,
        categories: this.item.categories,
        products: this.item.products,
      }

      this.$http.put(`/api/admin/coupons/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'coupons-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        type: item.type,
        code: item.code,
        value: item.value,
        minimum_spend: item.minimum_spend,
        maximum_spend: item.maximum_spend,
        usage_limit_per_coupon: item.usage_limit_per_coupon,
        started_at: item.started_at,
        ended_at: item.ended_at,
        categories: item.categories,
        products: item.products,
        title: item.title,
      }

      data.categories = _.map(data.categories, 'id')
      data.products = _.map(data.products, 'id')

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
